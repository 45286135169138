import { helperConstants } from '../_constants';

const initialState = {
    height: 100, currentpage: 1, currentSizePerPage: 10, showForm: false, mode: 0
    , showDialog: false, clientList: {}, warehouseList: {}, rptClientList: {}
};

export const helper = (state = initialState, action) => {
    switch (action.type) {
        case helperConstants.HELPER_REFRESH_WINDOW_SIZE:
            return {
                ...state,
                height: action.payload.height - 125,
            };
        case helperConstants.HELPER_UPDATE_PAGING:
            return {
                ...state,
                currentpage: action.payload.currentpage,
                currentSizePerPage: action.payload.currentSizePerPage,
            };
        case helperConstants.HELPER_SHOW_FORM:
            return {
                ...state,
                showForm: action.payload.showForm,
            };
        case helperConstants.HELPER_SHOW_DIALOG:
            return {
                ...state,
                showDialog: action.payload.showDialog,
            };
        case helperConstants.HELPER_MODE:
            return {
                ...state,
                mode: action.payload.mode,
            };
        case helperConstants.HELPER_SET_CLIENT_LIST:
            return {
                ...state,
                clientList: (action.payload),
            };
        case helperConstants.HELPER_SET_WAREHOUSE_LIST:
            return {
                ...state,
                warehouseList: (action.payload),
            };
        case helperConstants.HELPER_SET_RPT_CLIENT_LIST:
            return {
                ...state,
                rptClientList: JSON.parse(action.payload),
            };
        default:
            return state
    }
}