import { apiConstants } from '../_constants';

const initialState = { contacting: false, error_msg: '', message: '' };

export const contacting = (state = initialState, action) => {
  switch (action.type) {
    case apiConstants.API_START_CONTACTING:
      return {
        contacting: true,
        error_msg: '',
        message: '',
      };
    case apiConstants.API_END_CONTACTING:
      return {
        contacting: false,
        error_msg: (action.payload.error_msg === undefined || action.payload.error_msg === null)  ? '':action.payload.error_msg,
        message: (action.payload.message === undefined || action.payload.message === null)  ? '':action.payload.message,
      };
    case apiConstants.API_CLEAR_MESSAGE:
      return {
        error_msg: '',
        message: '',
      };
    default:
      return state
  }
}
