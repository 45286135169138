export const helperConstants = {
    HELPER_REFRESH_WINDOW_SIZE: 'HELPER_REFRESH_WINDOW_SIZE',
    HELPER_UPDATE_PAGING: 'HELPER_UPDATE_PAGING',
    HELPER_SHOW_FORM: 'HELPER_SHOW_FORM',
    HELPER_SHOW_DIALOG: 'HELPER_SHOW_DIALOG',
    HELPER_SUCCESSFULLY: 'DATA SAVE SUCCESSFULLY',
    HELPER_MODE: 'HELPER_MODE',
    HELPER_SET_CLIENT_LIST: 'HELPER_SET_CLIENT_LIST',
    HELPER_SET_WAREHOUSE_LIST: 'HELPER_SET_WAREHOUSE_LIST',
    HELPER_SET_RPT_LIST: 'HELPER_SET_RPT_LIST',
    HELPER_SET_RPT_CLIENT_LIST: 'HELPER_SET_RPT_CLIENT_LIST'
};