import { apiConstants, clientConstants, helperConstants } from '../_constants';
import APIService from "../_services/api";
import { userActions, helperActions } from '../_actions';

const getClientConfigList = (data, _filter, _sorting, _paging, _first, _last, _type, message) => async dispatch => {
    data = { "p_param": { "method": "fetch_client_config_list", "filter": _filter, "sorting": _sorting, "paging": _paging, "first": _first, "last": _last, "type": _type } }
    try {
        dispatch({
            type: apiConstants.API_START_CONTACTING
        })
        const res = await APIService.post('submit', data);

        if (res.data.error_msg === '') {

            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: message }
            })
            dispatch({
                type: clientConstants.CLIENT_FETCH_COMPLETED,
                payload: res.data.out_record
            })
            dispatch({
                type: helperConstants.HELPER_UPDATE_PAGING,
                payload: { "currentpage": (_paging.currentpage === _paging.page || _paging.sizePerPage !== _paging.currentSizePerPage) ? 1 : _paging.page, "currentSizePerPage": _paging.sizePerPage }
            })
        } else {
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: res.data.message }
            })
            if (res.data.status === 401) {
                dispatch(userActions.logout());

            }
        }
    }
    catch (e) {
        dispatch({
            type: apiConstants.API_END_CONTACTING,
            payload: { error_msg: 'contacting server failed' },
        })
    }

}


const updateClient = (data) => dispatch => {
    dispatch({
        type: clientConstants.CLIENT_SET_DATA, payload: data
    })
    dispatch({
        type: helperConstants.HELPER_MODE, payload: { "mode": 1 }
    })
}

const createClient = () => dispatch => {
    dispatch({
        type: clientConstants.CLIENT_SET_DATA, payload: { "client_id": "", "config_name": "", "config_value": "", "notes": "" }
    })
    dispatch({
        type: helperConstants.HELPER_MODE, payload: { "mode": 0 }
    })
}

const onSelectClient = (selected) => dispatch => {
    dispatch({
        type: clientConstants.CLIENT_DELETE_DATA, payload: { selected }
    })

}

const saveClientConfig = (_data, mode) => async dispatch => {
    let data = {}
    data = { "p_param": { "method": "save_client_config", "data": _data, "mode": mode } }
    try {
        dispatch({
            type: apiConstants.API_START_CONTACTING
        })
        const res = await APIService.post('submit', data);

        if (res.data.error_msg === '') {
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: helperConstants.HELPER_SUCCESSFULLY }
            })
            dispatch({
                type: clientConstants.CLIENT_FETCH_COMPLETED,
                payload: res.data.out_record
            })
            dispatch(helperActions.showForm(false))
        } else {
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: res.data.message }
            })
            if (res.data.status === 401) {
                dispatch(userActions.logout());

            }
        }
    }
    catch (e) {
        dispatch({
            type: apiConstants.API_END_CONTACTING,
            payload: { error_msg: 'contacting server failed' },
        })
    }

}

const deleteClientConfig = (id, client_id, config_name, _filters, _sorting, _paging, _first, _last, _type) => async dispatch => {

    let data = {}
    data = { "p_param": { "method": "delete_client_config", "id": id, "client_id": client_id, "config_name": config_name } }
    try {
        dispatch({
            type: apiConstants.API_START_CONTACTING
        })
        const res = await APIService.post('submit', data);

        if (res.data.error_msg === '') {
            const resultJson = JSON.parse(res.data.out_record);
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: resultJson.message }
            })

            dispatch(clientActions.getClientConfigList({}, _filters, _sorting, _paging, _first, _last, _type, resultJson.message));
            dispatch(helperActions.showDialog(false))

        } else {
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: res.data.message }
            })
            if (res.data.status === 401) {
                dispatch(userActions.logout());

            }
        }
    }
    catch (e) {
        dispatch({
            type: apiConstants.API_END_CONTACTING,
            payload: { error_msg: 'contacting server failed' },
        })
    }

}

export const clientActions = {
    getClientConfigList,
    updateClient,
    createClient,
    onSelectClient,
    saveClientConfig,
    deleteClientConfig
}