import { apiConstants, rptConstants, helperConstants } from '../_constants';
import APIService from "../_services/api";
import { userActions, helperActions } from '../_actions';

const getRptConfigList = (data, _filter, _sorting, _paging, _first, _last, _type, message) => async dispatch => {
    data = { "p_param": { "method": "fetch_rpt_config_list", "filter": _filter, "sorting": _sorting, "paging": _paging, "first": _first, "last": _last, "type": _type } }
    try {
        dispatch({
            type: apiConstants.API_START_CONTACTING
        })
        const res = await APIService.post('submit', data);

        if (res.data.error_msg === '') {

            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: message }
            })
            dispatch({
                type: rptConstants.RPT_FETCH_COMPLETED,
                payload: res.data.out_record
            })
            dispatch({
                type: helperConstants.HELPER_UPDATE_PAGING,
                payload: { "currentpage": (_paging.currentpage === _paging.page || _paging.sizePerPage !== _paging.currentSizePerPage) ? 1 : _paging.page, "currentSizePerPage": _paging.sizePerPage }
            })
        } else {
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: res.data.message }
            })
            if (res.data.status === 401) {
                dispatch(userActions.logout());

            }
        }
    }
    catch (e) {
        dispatch({
            type: apiConstants.API_END_CONTACTING,
            payload: { error_msg: 'contacting server failed' },
        })
    }

}


const updateRpt = (data) => dispatch => {
    dispatch({
        type: rptConstants.RPT_SET_DATA, payload: data
    })
    dispatch({
        type: helperConstants.HELPER_MODE, payload: { "mode": 1 }
    })
}

const createRpt = () => dispatch => {
    dispatch({
        type: rptConstants.RPT_SET_DATA, payload: { "client_id": "", "rpt_name": "", "document_type": ""}
    })
    dispatch({
        type: helperConstants.HELPER_MODE, payload: { "mode": 0 }
    })
}

const onSelectRpt = (selected) => dispatch => {
    dispatch({
        type: rptConstants.RPT_DELETE_DATA, payload: { selected }
    })

}

const saveRptConfig = (_data, mode) => async dispatch => {
    let data = {}
    data = { "p_param": { "method": "save_rpt_config", "data": _data, "mode": mode } }
    try {
        dispatch({
            type: apiConstants.API_START_CONTACTING
        })
        const res = await APIService.post('submit', data);

        if (res.data.error_msg === '') {
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: helperConstants.HELPER_SUCCESSFULLY }
            })
            dispatch({
                type: rptConstants.RPT_FETCH_COMPLETED,
                payload: res.data.out_record
            })
            dispatch(helperActions.showForm(false))
        } else {
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: res.data.message }
            })
            if (res.data.status === 401) {
                dispatch(userActions.logout());

            }
        }
    }
    catch (e) {
        dispatch({
            type: apiConstants.API_END_CONTACTING,
            payload: { error_msg: 'contacting server failed' },
        })
    }

}

const deleteRptConfig = (id, client_id, rpt_name, document_type, _filters, _sorting, _paging, _first, _last, _type) => async dispatch => {

    let data = {}
    data = { "p_param": { "method": "delete_rpt_config", "id": id, "client_id": client_id, "rpt_name": rpt_name, "document_type": document_type } }
    try {
        dispatch({
            type: apiConstants.API_START_CONTACTING
        })
        const res = await APIService.post('submit', data);

        if (res.data.error_msg === '') {
            const resultJson = JSON.parse(res.data.out_record);
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: resultJson.message }
            })

            dispatch(rptActions.getRptConfigList({}, _filters, _sorting, _paging, _first, _last, _type, resultJson.message));
            dispatch(helperActions.showDialog(false))

        } else {
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: res.data.message }
            })
            if (res.data.status === 401) {
                dispatch(userActions.logout());

            }
        }
    }
    catch (e) {
        dispatch({
            type: apiConstants.API_END_CONTACTING,
            payload: { error_msg: 'contacting server failed' },
        })
    }

}

export const rptActions = {
    getRptConfigList,
    updateRpt,
    createRpt,
    onSelectRpt,
    saveRptConfig,
    deleteRptConfig
}