import { useState, useEffect } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { userActions } from '../_actions';
import "./Login.css";

const Login = (props) => {
  
  const { user, count, url } = useSelector(state => state.authentication)
  const { contacting } = useSelector(state => state.contacting)
  
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    user_id: "",
    password: "",
    app_version: "",
    os_version: "",
    device_name: "",
    device_model: "",
    device_id: "",
    wsade: "0",
    wsade_code: "",
    errors: {},
    isLoading: false,
    redirectToReferrer: false
  });


  const validateForm = () => {

    return count < 3 ? form.user_id.length > 0 && form.password.length > 0 :
      form.user_id.length > 0 && form.password.length > 0 && form.wsade_code.length > 0;
  }

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(userActions.login(form));
  }

  useEffect(() => {
    dispatch(userActions.logout());
    dispatch(userActions.setCounter());
  }, [dispatch])


  const { from } = props.location.state || { from: { pathname: '/' } };

  let imgCaptcha;
  let formControlCaptcha;
  if (user) {
    return (
      <Redirect to={from} />
    )
  }

  if (count > 2) {
    formControlCaptcha = <FormGroup controlId="wsade_code" >
      <FormLabel>Captcha</FormLabel>
      <FormControl
        autoFocus
        type="text"
        value={form.wsade_code}
        onChange={handleChange}
      />
    </FormGroup>
    imgCaptcha =
      <img
        src={url}
        alt="new"
      />
  }
  return (

    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="user_id" >
          <FormLabel>User ID</FormLabel>
          <FormControl
            autoFocus
            type="text"
            value={form.user_id}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password" >
          <FormLabel>Password</FormLabel>
          <FormControl
            value={form.password}
            onChange={handleChange}
            type="password"
          />
        </FormGroup>
        {imgCaptcha}
        {formControlCaptcha}
        <Button
          block
          disabled={!validateForm() || contacting}
          type="submit"
        >
          Login
          </Button>
      </form>
    </div>
  );

}

export default Login
