import axios from "axios";
import authHeader from './auth-header';

const baseUrl = process.env.REACT_APP_URL

class APIService {
    
    post = (url,data) => {
         data['p_session_key'] =  localStorage.getItem('session');
        data['p_user_id'] =  localStorage.getItem('user');
        return axios({
            method: 'post',
            url: baseUrl+'/v2/'+url,
            headers: authHeader(), 
            data: data
          });
    }

    get = () => {
        return axios({
            method: 'get',
            url: baseUrl+'/sess/counter?c='+ new Date().getTime(),
            headers: authHeader(), 
            data: {}
          });
    }
}

export default new APIService();