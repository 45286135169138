import { Button, Modal, FormGroup, FormLabel } from 'react-bootstrap';

const Dialog = (props) => {


    return (
        <>
            <Modal show={true} onHide={props.handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup controlId="message_id" >
                        <FormLabel>{props.message}</FormLabel>

                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.handleSubmit}>
                        Yes
            </Button>
                    <Button variant="secondary" onClick={props.handleClose}>
                        No
            </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Dialog