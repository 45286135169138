import { useDispatch, useSelector } from "react-redux"
import ToolBar from "../../components/ToolBar";
import BootstrapTable from 'react-bootstrap-table-next';
import { clientActions, helperActions } from '../../_actions';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ClientForm from "../../containers/clients/ClientForm";
import Dialog from "../../components/Dialog";
import { useState, useEffect } from "react";
import AccessMenu from "../../_helpers/access-menu";

const defaultSorted = [{
    dataField: 'client_id',
    order: 'asc'
}, {
    dataField: 'config_name',
    order: 'asc'
}, {
    dataField: 'config_value',
    order: 'asc'
}, {
    dataField: 'notes',
    order: 'asc'
}];

const RemoteFilter = ({ data, page, sizePerPage, onTableChange, totalSize, clientList }) => {
    const { selected } = useSelector(state => state.client)
    const { menus } = useSelector(state => state.authentication);
    const dispatch = useDispatch();

    const rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            if (AccessMenu.validAccess(menus, 5703)) {
                dispatch(clientActions.updateClient(row))
                dispatch(helperActions.showForm(true))
            }

        },
        onClick: (e, row, rowIndex) => {
            if (AccessMenu.validAccess(menus, 5704)) {
                dispatch(clientActions.updateClient(row))
            }
            
        }
    };

    const columns = [{
        dataField: 'id',
        text: 'ID',
        hidden: true
    }, {
        dataField: 'client_id',
        text: 'Client ID',
        filter: selectFilter({
            options: clientList
        }),
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '200px', textAlign: 'center' };
        },

    }, {
        dataField: 'config_name',
        text: 'Config Name',
        filter: textFilter(),
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '300px', textAlign: 'center' };
        }
    }, {
        dataField: 'config_value',
        text: 'Config Value',
        filter: textFilter(),
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '100px', textAlign: 'center' };
        }
    }, {
        dataField: 'notes',
        text: 'Notes',
        filter: textFilter(),
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '500px', textAlign: 'center' };
        }
    }];

    const handleOnSelect = (row, isSelect) => {

        if (isSelect) {
            dispatch(clientActions.onSelectClient([row.id]))
        } else {
            dispatch(clientActions.onSelectClient([]))
        }
    }
    const selectRow = {
        mode: 'checkbox',
        selected: selected,
        clickToSelect: true,
        onSelect: handleOnSelect,
        hideSelectAll: true,

    };



    return <BootstrapTable
        bootstrap4
        remote
        keyField="id"
        data={data}
        columns={columns}
        filter={filterFactory()}
        pagination={paginationFactory({ page, sizePerPage, totalSize, "withFirstAndLast": false })}
        onTableChange={onTableChange}
        rowEvents={rowEvents}
        defaultSorted={defaultSorted}
        selectRow={selectRow}
        noDataIndication={'no results found'}
    />;
};

const ClientConfigList = () => {
    const { height, currentpage, currentSizePerPage, showForm, showDialog, clientList } = useSelector(state => state.helper);
    const dispatch = useDispatch();
    const { data, selected, client_id, config_name, id } = useSelector(state => state.client);
    const [_filters, setFilters] = useState({})
    const [_sorting, setSorting] = useState({})
    const [_paging, setPaging] = useState({})
    const [_first, setFirst] = useState({})
    const [_last, setLast] = useState({})


    const [mounted, setMounted] = useState(false)

    if (!mounted) {
        dispatch(helperActions.getClientList())
    }

    useEffect(() => {
        setMounted(true)
        // eslint-disable-next-line
    }, []);

    const handleTableChange = (type, { filters, sortField, sortOrder, page, sizePerPage }) => {
        dispatch(clientActions.onSelectClient([]))
        let sorting = { [sortField]: sortOrder }
        const paging = { currentpage, page, currentSizePerPage, sizePerPage }
        let first = {}
        let last = {}
        if (data.length > 0) {
            first = data[0]
            last = data[data.length - 1]
        }

        setFilters(filters)
        setSorting(sorting)
        setPaging(paging)
        setFirst(first)
        setLast(last)
        dispatch(clientActions.getClientConfigList({}, filters, sorting, paging, first, last, type));
    }

    const _createClientConfig = () => {
        dispatch(clientActions.createClient({}))
        dispatch(helperActions.showForm(true))
    };

    const _deleteClientConfig = () => {

        dispatch(helperActions.showDialog(true))
    }

    const _handleClose = () => {
        dispatch(helperActions.showDialog(false))
    }

    const _handleSubmit = () => {
        dispatch(clientActions.deleteClientConfig(id, client_id, config_name, _filters, _sorting, _paging, _first, _last, 'sort'))
    }

    return (

        <div>
            <ToolBar menu="Client Config"
                create={_createClientConfig}
                delete={_deleteClientConfig} showDeleted={selected.length > 0} />
            <div style={{ position: "relative", height: height, overflow: "auto" }}>
                <RemoteFilter

                    data={data}
                    page={currentpage}
                    sizePerPage={currentSizePerPage}
                    totalSize={data.length > 0 ? data[0].tot_rec : 0}
                    onTableChange={handleTableChange}
                    clientList={clientList}
                />
                {showForm && <ClientForm />}
                {showDialog && <Dialog handleClose={_handleClose} handleSubmit={_handleSubmit}
                    message={"Delete Client Config '" + client_id + " " + config_name + "'?"} />}

            </div>
        </div>
    )

}
export default ClientConfigList
