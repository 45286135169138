import { Button, Modal, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux"
import { helperActions } from '../../_actions';
import { useState, useEffect } from "react";
import { rptActions, userActions } from '../../_actions';
import Spinner from "react-spinkit";
import { Alert } from "react-bs-notifier";
const RptForm = () => {
    const { contacting, error_msg } = useSelector(state => state.contacting);
    const {  mode } = useSelector(state => state.helper)
    const { client_id, rpt_name, document_type,id } = useSelector(state => state.rpt)
    const dispatch = useDispatch();
     const [form, setForm] = useState({
        client_id: "",
        rpt_name: "",
        document_type: "",
        id: ""

    });

    const handleDismiss = () => {
        dispatch(userActions.clearMessage());
      }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(rptActions.saveRptConfig(form, mode));
    }

    const handleClose = () => dispatch(helperActions.showForm(false));

    const handleChange = event => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };
    
    useEffect(() => { 
        setForm({ ...form,client_id, rpt_name, document_type, "id": id?id:'' })  
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Modal show={true} onHide={handleClose} backdrop="static" centered>
            <div style={{ paddingLeft: '5px' }}>
                {error_msg !== '' ? (<div><Alert timeout={2000} type='warning' onDismiss={handleDismiss} >{error_msg}</Alert></div>) : null}

            </div>
                <Modal.Header closeButton>
                    <Modal.Title>{contacting && <Spinner name="line-scale" />}Report Template Config</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <FormGroup controlId="client_id" >
                            <FormLabel>Client ID</FormLabel>
                            <FormControl
                                autoFocus
                                type="text"
                                value={form.client_id}
                                onChange={handleChange}

                            />
                        </FormGroup>
                        <FormGroup controlId="document_type" >
                            <FormLabel>Document Type</FormLabel>
                            <FormControl
                                value={form.document_type}
                                onChange={handleChange}
                                type="text"
                            />
                        </FormGroup>
                        <FormGroup controlId="rpt_name" >
                            <FormLabel>Report Name</FormLabel>
                            <FormControl
                                value={form.rpt_name}
                                onChange={handleChange}
                                type="text"

                            />
                        </FormGroup>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
            </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            Save Changes
            </Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </div>
    );

};
export default RptForm