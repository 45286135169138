import { apiConstants, userConstants } from '../_constants';
import APIService from "../_services/api";

const login = (data) => async dispatch => {
  if (data.wsade_code !== '') {
    data['wsade'] = "1"
  }
  try {
    dispatch({
      type: apiConstants.API_START_CONTACTING
    })
    const res = await APIService.post('login', data);

    if (res.data.p_user_id) {

      localStorage.setItem('user', res.data.p_user_id);
      localStorage.setItem('token', res.data.p_session_token);
      localStorage.setItem('session', res.data.p_session_key);
      localStorage.setItem('menus', res.data.vis_menus);
      dispatch({
        type: apiConstants.API_END_CONTACTING,
        payload: { error_msg: res.data.error_msg, message: res.data.message }
      })
      dispatch({
        type: userConstants.USER_LOGIN_SUCCESS, user: res.data.p_user_id, token: res.data.p_session_token
        , menus: res.data.vis_menus, session: res.data.p_session_key
      })
    } else {
      dispatch({
        type: userConstants.USER_LOGIN_FAILED, count: res.data.count
      })
      dispatch({
        type: apiConstants.API_END_CONTACTING,
        payload: { error_msg: res.data.error_msg, message: res.data.message }
      })
    }
  }
  catch (e) {
    dispatch({
      type: apiConstants.API_END_CONTACTING,
      payload: { error_msg: 'contacting server failed...' },
    })
  }

}

const clearMessage = () => {
  return { type: apiConstants.API_CLEAR_MESSAGE };
}

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('session');
  localStorage.removeItem('user');
  return { type: userConstants.USER_LOGOUT };
}

const authenticated = (res) => {
  localStorage.setItem('user', res.data.p_user_id);
  return { type: userConstants.USER_LOGIN_SUCCESS, user: res.data.p_user_id };
}

const sessionUpdate = () => async dispatch => {
  let user = (localStorage.getItem('user'));
  let token = (localStorage.getItem('token'));
  let session = (localStorage.getItem('session'));
  let data = { "p_session_key": session, "p_user_id": user, "p_param": { "p_session_id": token } }
  try {

    if (user) {
      await APIService.get();
      await APIService.post('session_update', data);
    }
  }
  catch (e) { }
}

const setCounter = () => async dispatch => {
  try {
    await APIService.get();
  }
  catch (e) { }
}
export const userActions = {
  login,
  logout,
  authenticated,
  clearMessage,
  sessionUpdate,
  setCounter
}