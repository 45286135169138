import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./containers/Home";

import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import ClientConfigList from "./containers/clients/ClientConfigList.js";
import WarehouseConfigList from "./containers/warehouses/WarehouseConfigList.js";
import RptConfigList from "./containers/rpts/RptConfigList.js";
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    localStorage.getItem('user') ? (
      <Component {...props} />
    ) :
      (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
      )

  )} />
)

const Routes = () => {

  return (
    <div>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" exact component={Login} />
        <PrivateRoute path="/client-list" exact component={ClientConfigList} />
        <PrivateRoute path="/warehouse-list" exact component={WarehouseConfigList} />
        <PrivateRoute path="/rpt-list" exact component={RptConfigList} />
        { /* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
      </Switch>
    </div>
  )

}
export default Routes