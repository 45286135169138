class AccessMenu {

    validAccess = (menus, id) => {
        if (menus !== undefined && menus !== null) {
            const _menu = JSON.parse(menus);
            if (_menu.find(t => t.menu_id === id) !== undefined) {
                return true
            } else { return false }

        } else { return false }

    }


}

export default new AccessMenu();