import { rptConstants } from '../_constants';

const initialState = { data: [], client_id: "", rpt_name: "", document_type: "", id: "", selected: [] };

export const rpt = (state = initialState, action) => {
    switch (action.type) {
        case rptConstants.RPT_FETCH_COMPLETED:
            return {
                ...state,
                data: JSON.parse(action.payload),
            };
        case rptConstants.RPT_RESET:
            return initialState;
        case rptConstants.RPT_SET_DATA:
            return {
                ...state,
                client_id: action.payload.client_id, rpt_name: action.payload.rpt_name
                , document_type: action.payload.document_type, id: action.payload.id
            };
        case rptConstants.RPT_DELETE_DATA:
            return {
                ...state,
                selected: action.payload.selected
            };
        default:
            return state
    }
}
