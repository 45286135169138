import { apiConstants, helperConstants } from '../_constants';
import APIService from "../_services/api";
import { userActions } from '../_actions';
const refreshWindowSize = (width, height) => {
    return { type: helperConstants.HELPER_REFRESH_WINDOW_SIZE, payload: { 'width': width, 'height': height } };
}

const updatePaging = (currentpage, page, sizePerPage) => {
    return { type: helperConstants.HELPER_UPDATE_PAGING, payload: { 'currentpage': currentpage, 'page': page, 'sizePerPage': sizePerPage } };
}

const showForm = (showForm) => {
    return { type: helperConstants.HELPER_SHOW_FORM, payload: { showForm } };
}

const showDialog = (showDialog) => {
    return { type: helperConstants.HELPER_SHOW_DIALOG, payload: { showDialog } };
}
const listUpdate = () => {
    return { type: helperConstants.HELPER_LIST_UPDATED };
}

const getClientList = (message) => async dispatch => {
    let data = { "p_param": {} }
    try {
        dispatch({
            type: apiConstants.API_START_CONTACTING
        })
        const res = await APIService.post('client_list', data);

        if (res.data.error_msg === '') {
            var rec = JSON.parse(res.data.out_record)
            rec.unshift({"client_id":"<>"})
            var result = rec.reduce(function(map, obj) {
                map[obj.client_id] = obj.client_id;
                return map;
            }, {});

            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: message }
            })
            dispatch({
                type: helperConstants.HELPER_SET_CLIENT_LIST,
                payload: result
            })
            dispatch({
                type: helperConstants.HELPER_SET_RPT_CLIENT_LIST,
                payload: res.data.out_record
            })
        } else {
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: res.data.message }
            })
            if (res.data.status === 401) {
                dispatch(userActions.logout());

            }
        }
    }
    catch (e) {
        dispatch({
            type: apiConstants.API_END_CONTACTING,
            payload: { error_msg: 'contacting server failed' },
        })
    }

}

const getWarehouseList = (message) => async dispatch => {
    let data = { "p_param": {} }
    try {
        dispatch({
            type: apiConstants.API_START_CONTACTING
        })
        const res = await APIService.post('warehouse_list', data);

        if (res.data.error_msg === '') {
            
            var rec = JSON.parse(res.data.out_record)
            rec.unshift({"warehouse_id":"<>"})
            var result = rec.reduce(function(map, obj) {
                map[obj.warehouse_id] = obj.warehouse_id;
                return map;
            }, {});
            
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: message }
            })
            dispatch({
                type: helperConstants.HELPER_SET_WAREHOUSE_LIST,
                payload: result
            })
        } else {
            dispatch({
                type: apiConstants.API_END_CONTACTING,
                payload: { error_msg: res.data.error_msg, message: res.data.message }
            })
            if (res.data.status === 401) {
                dispatch(userActions.logout());

            }
        }
    }
    catch (e) {
        dispatch({
            type: apiConstants.API_END_CONTACTING,
            payload: { error_msg: 'contacting server failed' },
        })
    }

}

export const helperActions = {
    refreshWindowSize,
    updatePaging,
    showForm,
    showDialog,
    listUpdate,
    getClientList,
    getWarehouseList
}