import "./Home.css";

const Home = () => {

    return (
      <div className="Home">
        <div className="lander">
          <h1>Javelin-Configurator</h1>
          <h4>{process.env.REACT_APP_MODE}</h4>
          <p>A simple application to manage your warehouse</p>
        </div>
      </div>
    );

}

export default Home