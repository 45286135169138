import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import Routes from "./Routes";
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import './App.css';
import Spinner from "react-spinkit";
import { Alert } from "react-bs-notifier";
import { Container } from 'react-bootstrap';
import { userActions, helperActions } from './_actions';
import WindowSizeListener from 'react-window-size-listener'
import AccessMenu from "./_helpers/access-menu";
const App = (props) => {

  const dispatch = useDispatch()
  const { user, menus } = useSelector(state => state.authentication);
  const { contacting, error_msg, message } = useSelector(state => state.contacting);
  const { showForm } = useSelector(state => state.helper);
  
  const handleDismiss = () => {
    dispatch(userActions.clearMessage());
  }

  return (
    <div>

      <WindowSizeListener onResize={windowSize => {
        dispatch(helperActions.refreshWindowSize(windowSize.windowWidth, windowSize.windowHeight));

      }} />
      <Navbar bg="light" expand="lg" sticky="top">
        <Navbar.Brand>
          {contacting && !showForm &&<Spinner name="line-scale" />}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {contacting ? (<div><Nav.Link href="/">Home{process.env.REACT_APP_MODE}</Nav.Link></div>) : (<Nav.Link href="/">Home{process.env.REACT_APP_MODE}</Nav.Link>)}
            <NavDropdown title="Configuration" id="basic-nav-dropdown">
              {AccessMenu.validAccess(menus,5702)  && <NavDropdown.Item href="/client-list">Client</NavDropdown.Item>} 
              {AccessMenu.validAccess(menus,5802)  && <NavDropdown.Item href="/warehouse-list">Warehouse</NavDropdown.Item>}
              {AccessMenu.validAccess(menus,5902)  && <NavDropdown.Item href="/rpt-list">Report</NavDropdown.Item>}

            </NavDropdown>
          </Nav>
          <Nav className="justify-content-end">
            {user ? (
              <Nav.Link href="">{user}</Nav.Link>
            ) : (
                null
              )}
            {user ? (
              <Nav.Link href="/login">Logout</Nav.Link>
            ) : (
                <Nav.Link href="/login">Login</Nav.Link>
              )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div style={{ paddingLeft: '5px' }}>
        {error_msg !== '' && !showForm ? (<div><Alert timeout={2000} type='warning' onDismiss={handleDismiss} >{error_msg}</Alert></div>) : null}
        {message !== '' ? (<div><Alert timeout={2000} type='success' onDismiss={handleDismiss} >{message}</Alert></div>) : null}

      </div>

      <Container width={"100%"} height={"100%"} ><Routes /></Container>

    </div>
  );

}

export default withRouter(App);
