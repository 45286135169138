import { Button, Modal, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux"
import { helperActions } from '../../_actions';
import { useState, useEffect } from "react";
import { clientActions, userActions } from '../../_actions';
import Spinner from "react-spinkit";
import { Alert } from "react-bs-notifier";

const ClientForm = () => {
    const { contacting, error_msg } = useSelector(state => state.contacting);
    const {  mode } = useSelector(state => state.helper)
    const { client_id, config_name, config_value, notes,id } = useSelector(state => state.client)
    const dispatch = useDispatch();
     const [form, setForm] = useState({
        client_id: "",
        config_name: "",
        config_value: "",
        notes: "",
        id: ""

    });

    const handleDismiss = () => {
        dispatch(userActions.clearMessage());
      }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(clientActions.saveClientConfig(form, mode));
    }

    const handleClose = () => dispatch(helperActions.showForm(false));

    const handleChange = event => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };
    
    useEffect(() => { 
        setForm({ ...form,client_id, config_name, "config_value": config_value?config_value:'', "notes": notes?notes:'', "id": id?id:'' })  
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Modal show={true} onHide={handleClose} backdrop="static" centered>
            <div style={{ paddingLeft: '5px' }}>
                {error_msg !== '' ? (<div><Alert timeout={2000} type='warning' onDismiss={handleDismiss} >{error_msg}</Alert></div>) : null}

            </div>
                <Modal.Header closeButton>
                    <Modal.Title>{contacting && <Spinner name="line-scale" />}Client Config</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <FormGroup controlId="client_id" >
                            <FormLabel>Client ID</FormLabel>
                            <FormControl
                                autoFocus
                                type="text"
                                value={form.client_id}
                                onChange={handleChange}

                            />
 
                        </FormGroup>
                        <FormGroup controlId="config_name" >
                            <FormLabel>Config Name</FormLabel>
                            <FormControl
                                value={form.config_name}
                                onChange={handleChange}
                                type="text"

                            />
                        </FormGroup>
                        <FormGroup controlId="config_value" >
                            <FormLabel>Config Value</FormLabel>
                            <FormControl
                                value={form.config_value}
                                onChange={handleChange}
                                type="text"
                            />
                            
                        </FormGroup>
                        <FormGroup controlId="notes" >
                            <FormLabel>Notes</FormLabel>
                            <FormControl
                                value={form.notes}
                                onChange={handleChange}
                                type="text"
                            />
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
            </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            Save Changes
            </Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </div>
    );

};
export default ClientForm