import { clientConstants } from '../_constants';

const initialState = { data: [], client_id: "", config_name: "", config_value: "", notes: "",id:"", selected: [] };

export const client = (state = initialState, action) => {
    switch (action.type) {
        case clientConstants.CLIENT_FETCH_COMPLETED:
            return {
                ...state,
                data: JSON.parse(action.payload),
            };
        case clientConstants.CLIENT_RESET:
            return initialState;
        case clientConstants.CLIENT_SET_DATA:
            return {
                ...state,
                client_id: action.payload.client_id, config_name: action.payload.config_name
                , config_value: action.payload.config_value, notes: action.payload.notes, id: action.payload.id
            };

            case clientConstants.CLIENT_DELETE_DATA:
                return {
                    ...state,
                    selected: action.payload.selected
                };
        default:
            return state
    }
}
