import { userConstants } from '../_constants';

let user = (localStorage.getItem('user'));
let token = (localStorage.getItem('token'));
let session = (localStorage.getItem('session'));
let menus = (localStorage.getItem('menus'));
let count = 0;
let url = "/captcha/captcha.png?c="+ new Date().getTime();

const initialState = user ? { user, token, session, count, url, menus} : {count: count};

export const authentication = (state = initialState, action) => {
  switch (action.type) {

    case userConstants.USER_LOGIN_SUCCESS:
      return {...state,
        user: action.user,
        token: action.token,
        session: action.session,
        menus: action.menus,
        count: count
      };
      case userConstants.USER_LOGIN_FAILED:
        return {
          count: action.count,
          url: "/captcha/captcha.png?c="+ new Date().getTime()
        };
    case userConstants.USER_LOGOUT:
      return {count: 0};
    default:
      return state
  }
}
