import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './_helpers';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { Container } from 'react-bootstrap';

ReactDOM.render(

  <Provider store={store}>
    <Router>
      <Container>
        <App store={store} />
      </Container>

    </Router>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
