import { combineReducers } from 'redux';
import { contacting } from './contacting.reducer';
import { authentication } from './authentication.reducer';
import { client } from './client.reducer';
import { warehouse } from './warehouse.reducer';
import { rpt } from './rpt.reducer';
import { helper } from './helper.reducer';
const rootReducer = combineReducers({
  contacting, authentication, client, warehouse, rpt, helper
});

export default rootReducer;
