import { warehouseConstants } from '../_constants';

const initialState = { data: [], warehouse_id: "", config_name: "", config_value: "", notes: "", id: "", selected: [] };

export const warehouse = (state = initialState, action) => {
    switch (action.type) {
        case warehouseConstants.WAREHOUSE_FETCH_COMPLETED:
            return {
                ...state,
                data: JSON.parse(action.payload),
            };
        case warehouseConstants.WAREHOUSE_RESET:
            return initialState;
        case warehouseConstants.WAREHOUSE_SET_DATA:
            return {
                ...state,
                warehouse_id: action.payload.warehouse_id, config_name: action.payload.config_name
                , config_value: action.payload.config_value, notes: action.payload.notes, id: action.payload.id
            };

        case warehouseConstants.WAREHOUSE_DELETE_DATA:
            return {
                ...state,
                selected: action.payload.selected
            };
        default:
            return state
    }
}
