import { useState } from "react";
import { Button, ButtonToolbar, ButtonGroup, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessMenu from "../_helpers/access-menu";

const ToolBar = (props) => {
  const [menu] = useState(props.menu)
  const { menus } = useSelector(state => state.authentication);

  const _deleteClientConfig = () => {
    props.delete()
  }

  const _createClientConfig = () => {
    props.create()
  }




  const deleteRowTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete {menu}
    </Tooltip>
  );

  const addTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Create {menu}
    </Tooltip>
  );
  return (
    
    <div>

      <div style={{ textAlign: "center" }}>{menu}</div>


      <ButtonToolbar aria-label="Toolbar with button groups">
        {/* <div/> */}
        {AccessMenu.validAccess(menus, 5701) && <ButtonGroup className="mr-2" aria-label="Second group">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={addTooltip}
          >
            <Button><AddIcon onClick={_createClientConfig} /></Button>

          </OverlayTrigger>

          {/* <Button><FontAwesomeIcon icon={faCoffee}  onClick={() => this.open()}/></Button> */}
        </ButtonGroup>}
        {AccessMenu.validAccess(menus, 5704) && <ButtonGroup className="mr-2" aria-label="First group">
          {props.showDeleted && <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={deleteRowTooltip}
          >
            <Button><DeleteIcon onClick={_deleteClientConfig} /></Button>
          </OverlayTrigger>}
        </ButtonGroup>}
      </ButtonToolbar>
      <br />
    </div>
  );


}

export default ToolBar